import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gsap, { Power2 } from 'gsap'
import styles from './NextPageTrigger.module.css'
import LinkTransition from './navigation/LinkTransition'
import { GlobalDispatchContext } from '../containers/GlobalContextProvider'
import { navigate } from 'gatsby'

export default class NextPageTrigger extends Component {
  static contextType = GlobalDispatchContext

  static propTypes = {
    shown: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    addExtraHeight: PropTypes.bool,
    zIndex: PropTypes.number,
    title: PropTypes.string,
    link: PropTypes.string,
    position: PropTypes.oneOf(['relative', 'fixed']),
    beforePageChange: PropTypes.func
  }

  static defaultProps = {
    position: 'fixed'
  }

  constructor(props) {
    super(props)

    this.virtualScrollInstance = null
    this.circleRef = React.createRef()
    this.linkRef = React.createRef()
    this.wrapRef = React.createRef()

    this.thresholdReached = false
    this.triggered = false

    this.overScrollMultiplier = 0.2
    this.scrollCricleEnabled = false

    this.state = {
      shown: props.shown,
      circle: {
        r: 0,
        circumference: 0,
        strokeWidth: 0
      }
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.onScroll)

      this.isTouch =
        typeof window !== 'undefined' ? window.matchMedia('(pointer: coarse)').matches : false

      const r = this.isTouch ? 20 : 70
      const strokeWidth = this.isTouch ? 3 : 6
      const circumference = Math.PI * r * 2
      this.setState({ circle: { r, circumference, strokeWidth } })
      this.currentDashOffset = circumference

      import('virtual-scroll').then(module => {
        const Scroller = module.default
        this.virtualScrollInstance = new Scroller({
          touchMultiplier: 4
        })
        this.virtualScrollInstance.on(this.onVirtualScroll, this)
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shown !== this.props.shown) {
      this.setState({ shown: this.props.shown })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
    if (this.virtualScrollInstance) this.virtualScrollInstance.destroy()
  }

  onVirtualScroll(e) {
    if (!this.thresholdReached || this.triggered || this.isTouch || !this.props.enabled)
      return false
    const { deltaY } = e

    // If deltaY is negative user is scrolling down
    // Animate circle's circumference as user scrolls down

    if (deltaY <= 0) {
      this.currentDashOffset = this.currentDashOffset - Math.abs(deltaY * this.overScrollMultiplier)
      if (this.currentDashOffset <= 0) {
        this.triggered = true
        this.currentDashOffset = 0
        this.changePage()
      } else {
        this.updateCircle()
      }
    } else {
      this.currentDashOffset = this.state.circle.circumference
      this.updateCircle(0.8)
    }
  }

  changePage() {
    if (this.props.beforePageChange) this.props.beforePageChange()
    this.context({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })
    setTimeout(() => {
      window.scrollTo(0, 0)
      setTimeout(() => {
        navigate(this.props.link)
      }, 50)
    }, 650)
  }

  onScroll = () => {
    if (!this.props.enabled) return false
    const scrollY = window.scrollY
    const docHeight = this.getDocumentHeight()
    const startCircleOffset = this.isTouch ? window.innerHeight * 2 : 50

    if (scrollY + window.innerHeight > docHeight - startCircleOffset) {
      if (!this.thresholdReached) {
        this.thresholdReached = true
        if (this.isTouch) this.showTouchNextPageTrigger()
      }
    } else {
      if (this.thresholdReached) {
        this.thresholdReached = false
        if (this.isTouch) this.hideTouchNextPageTrigger()
      }
    }

    if (this.thresholdReached && this.isTouch)
      this.animateTouchCircle(docHeight - startCircleOffset)
  }

  animateTouchCircle(startingScroll) {
    // Animate circle dash offset according to scroll
    // Some kind of parallax effect. As user scrolls decrease circle's dashoffset from its radius to 0
    const deltaScroll = window.scrollY - startingScroll + window.innerHeight
    const triggerAfter = window.innerHeight * 1.5 // Trigger next page (end circle animation) after one vh
    const progress = deltaScroll / triggerAfter
    this.currentDashOffset =
      this.state.circle.circumference - this.state.circle.circumference * progress
    if (this.currentDashOffset <= 0) {
      this.changePage()
    } else {
      this.updateCircle()
    }
  }

  showTouchNextPageTrigger() {
    gsap.fromTo(this.wrapRef.current, { y: '100%' }, { y: 0, duration: 0.6, ease: Power2.easeOut })
  }

  hideTouchNextPageTrigger() {
    gsap.to(this.wrapRef.current, { y: '100%', duration: 0.6, ease: Power2.easeOut })
  }

  updateCircle(duration = 0.4) {
    gsap.to(this.circleRef.current, {
      strokeDashoffset: this.currentDashOffset,
      duration
    })
  }

  getDocumentHeight() {
    const D = document
    return Math.max(
      D.body.scrollHeight,
      D.documentElement.scrollHeight,
      D.body.offsetHeight,
      D.documentElement.offsetHeight,
      D.body.clientHeight,
      D.documentElement.clientHeight
    )
  }

  completeCircle() {
    gsap.killTweensOf(this.circleRef.current)
    return new Promise((resolve, reject) => {
      gsap.to(this.circleRef.current, {
        strokeDashoffset: 0,
        duration: 0.1,
        onComplete() {
          resolve()
        }
      })
    })
  }

  onLinkClick() {
    this.completeCircle().then(() => {
      this.context({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })
    })
  }

  render() {
    return (
      <>
        {this.props.addExtraHeight && <div className={styles.extraHeightDiv}></div>}
        <div className={styles.root} style={{ zIndex: this.props.zIndex }} ref={this.wrapRef}>
          <div
            className={`${styles.inner} ${styles[this.props.position]}`}
            style={{ display: this.state.shown ? 'block' : 'none' }}
          >
            <div className={styles.content}>
              <LinkTransition
                to={this.props.link}
                exitTrigger={this.onLinkClick.bind(this)}
                exitDelay={1}
                entryDelay={1.3}
              ></LinkTransition>
              <div className={styles.pageLink} ref={this.linkRef}>
                <span>{this.props.title}</span>
                <span className={styles.triangleRight}></span>
              </div>
              <div className={styles.circle}>
                <svg height="150" width="150">
                  <circle
                    cx="75"
                    cy="75"
                    r={this.state.circle.r}
                    stroke="var(--color-white)"
                    strokeWidth={this.state.circle.strokeWidth}
                    fillOpacity="0"
                    ref={this.circleRef}
                    strokeDasharray={this.state.circle.circumference}
                    strokeDashoffset={this.state.circle.circumference}
                  />
                  <polygon
                    points="75,75 95,75 85,90"
                    transform="translate(-10 -5)"
                    className={styles.polygon}
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
