import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import styles from './UfoAnimation.module.css'
import UfoSvg from './UfoSvg'

export default class UfoAnimation extends Component {
  static propTypes = {
    play: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired
  }
  constructor(props) {
    super(props)

    this.beforeOpen = 3.3 /* čas preden se odpre okno */
    this.afterOpen = 2 /* čas dokler animacija začne znova */

    this.state = {
      isShown: false
    }
  }

  componentDidMount() {
    this.master = gsap.timeline({ repeat: -1, paused: true })
    this.float = gsap.timeline({ repeat: -1, paused: true })
    this.cnst = this.float.add(this.constants())
    this.master.add(this.elvis(this.beforeOpen, this.afterOpen))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({ isShown: this.props.show })
      this.props.show ? this.play() : this.pause()
    }
    if (prevProps.play !== this.props.play) {
      this.props.play ? this.play() : this.pause()
    }
  }

  componentWillUnmount() {}

  play() {
    this.master.play()
    this.float.play()
  }

  pause() {
    this.master.pause()
    this.float.pause()
  }

  elvis() {
    var tl = gsap.timeline({ repeat: -1, repeatDelay: this.afterOpen })

    /* open glass */
    tl.to('#glass', { duration: 0.5, rotation: -120, transformOrigin: '0% 85%' }, this.beforeOpen)

    tl.fromTo(
      '#right-hand',
      { rotation: -110, transformOrigin: '50% 5%' },
      { duration: 0.23, rotation: -180, transformOrigin: '50% 5%' }
    )

    tl.to('#right-hand', {
      duration: 0.08,
      rotation: -171,
      ease: 'slow(0.1, 1, true)',
      yoyoEase: true,
      yoyo: true,
      repeat: 4,
      repeatDelay: 0.1,
      transformOrigin: '50% 5%'
    })
    tl.to('#right-hand', { duration: 0.4, rotation: -110, transformOrigin: '50% 5%' })

    /* mouth */
    tl.to('#mouth', { duration: 0.05, rotation: 180, transformOrigin: 'center', delay: 0.85 })

    /* raise hands */
    tl.to('#right-hand', { duration: 0.23, rotation: -180, transformOrigin: '50% 5%' })
    tl.to('#left-hand', { duration: 0.23, rotation: 0, transformOrigin: '50% 85%' })

    /* throwing */
    tl.to('#right-hand', {
      duration: 0.3,
      scaleY: -0.8,
      transformOrigin: 'center 10%',
      ease: 'power1.out',
      repeat: 11,
      yoyo: true
    })
    tl.to(
      '#left-hand',
      {
        duration: 0.3,
        scaleY: -0.8,
        transformOrigin: 'center 90%',
        ease: 'power1.out',
        delay: 0.5,
        repeat: 11,
        yoyo: true
      },
      '-=3.8'
    )

    /* hands falling */
    tl.to('#right1', { display: 'block' }, '-=3.8')
    tl.to('#left1', { display: 'block' }, '-=3.5')
    tl.to('#right2', { display: 'block' }, '-=3.2')
    tl.to('#left2', { display: 'block' }, '-=2.9')
    tl.to('#right3', { display: 'block' }, '-=2.6')
    tl.to('#left3', { display: 'block' }, '-=2.3')

    let fallingArmsTarget = '120vh'

    if (window.innerWidth < 2000) fallingArmsTarget = '130vh'
    if (window.innerWidth < 1800) fallingArmsTarget = '180vh'
    if (window.innerWidth < 1200) fallingArmsTarget = '200vh'

    tl.fromTo('#right1', { y: '24vh' }, { duration: 2, y: fallingArmsTarget }, '-=3.8')
    tl.fromTo('#left1', { y: '24vh' }, { duration: 2, y: fallingArmsTarget }, '-=3.5')
    tl.fromTo('#right2', { y: '24vh' }, { duration: 2, y: fallingArmsTarget }, '-=3.2')
    tl.fromTo('#left2', { y: '24vh' }, { duration: 2, y: fallingArmsTarget }, '-=2.9')
    tl.fromTo('#right3', { y: '24vh' }, { duration: 2, y: fallingArmsTarget }, '-=2.6')
    tl.fromTo('#left3', { y: '24vh' }, { duration: 2, y: fallingArmsTarget }, '-=2.3')
    tl.fromTo('#right1', { y: '24vh' }, { duration: 2, y: fallingArmsTarget }, '-=2')
    tl.fromTo('#left1', { y: '24vh' }, { duration: 2, y: fallingArmsTarget }, '-=1.7')
    tl.fromTo('#right2', { y: '24vh' }, { duration: 2, y: fallingArmsTarget }, '-=1.7')
    tl.fromTo('#left2', { y: '24vh' }, { duration: 2, y: fallingArmsTarget }, '-=1.7')
    tl.fromTo('#right3', { y: '24vh' }, { duration: 2, y: fallingArmsTarget }, '-=1.7')
    tl.fromTo('#left3', { y: '24vh' }, { duration: 2, y: fallingArmsTarget }, '-=1.6')

    /* lower hands */
    tl.to('#right-hand', { duration: 0.23, rotation: -110, transformOrigin: '50% 5%' }, '-=1.6')
    tl.to('#left-hand', { duration: 0.23, rotation: -70, transformOrigin: '50% 85%' }, '-=1.6')

    /* close glass */
    tl.to('#glass', { duration: 0.5, rotation: 0, transformOrigin: '0% 85%' }, '-=1.3')
    return tl
  }

  constants() {
    var tl = gsap.timeline()
    /* fire */
    tl.to('#fire-inner', { duration: 0.1, scaleY: 1.35, repeat: -1, yoyo: true })
    tl.to('#fire-outer', { duration: 0.1, scaleY: 1.11, repeat: -1, yoyo: true }, '-=0.1')
    /* ufo hover */
    tl.to('#whole-ufo', {
      duration: 2,
      translateY: 100,
      ease: 'Power1.easeInOut',
      repeat: -1,
      yoyoEase: true,
      yoyo: true
    })
    return tl
  }

  render() {
    return (
      <div className={`${styles.root} ${this.state.isShown ? styles.show : ''}`}>
        <UfoSvg show={this.props.load}></UfoSvg>
      </div>
    )
  }
}
