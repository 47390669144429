import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './FixedHeadline.module.css'

export default class FixedHeadline extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className={styles.root}>
        <div className={`${styles.contentInner} ${this.props.isSticky ? styles.sticky : ''}`}>
          <div className={`${styles.content}`} ref={this.ref}>
            <h1 className={styles.title}>{this.props.text}</h1>
          </div>
        </div>
      </div>
    )
  }
}
