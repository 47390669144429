import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import arrDown from '../../images/arr_down.svg'
import styles from './HeroContent.module.css'
import { GlobalStateContext } from '../../containers/GlobalContextProvider'

function HeroContent(props) {
  const content = {
    sl: {
      title: 'Tisti 1 % več',
      subtitle:
        'Dobrodošli v Agenciji 101. Pri nas ustvarjamo poslovno-marketinške rešitve, ki imajo tisti 1 % več.',
      stronger: 'In to vedno - tudi, če se pojavi NLP z Elvisom, ki v nas meče kratke roke.'
    },
    en: {
      title: 'That 1 % more',
      subtitle:
        'Welcome to Agencija 101. We create business & marketing solutions with that 1% more. ',
      stronger: 'To reach it we will go the extra mile under any circumstance. '
    }
  }
  const context = useContext(GlobalStateContext)
  const [isFullHeight, setFullHeight] = useState(true)

  useEffect(() => {
    setFullHeight(context.viewport.isFullHeight)
  }, [context.viewport])

  return (
    <div className={`${styles.content} ${!isFullHeight ? styles.extraBottom : ''}`}>
      <h1 id="hero-content-title" className={styles.title}>
        {content[props.locale].title}
      </h1>
      <p id="hero-content-subtitle" className={styles.paragraph}>
        {content[props.locale].subtitle}
      </p>
      <p id="hero-content-stronger" className={styles.paragraph}>
        <strong>{content[props.locale].stronger}</strong>
      </p>
      <div className={styles.icon} id="hero-content-arrow">
        <img src={arrDown} alt="" />
      </div>
    </div>
  )
}

HeroContent.propTypes = {
  locale: PropTypes.oneOf(['sl', 'en']).isRequired,
  skipAnimations: PropTypes.bool
}

export default HeroContent
