import React, { useEffect, useContext, useState, useRef } from 'react'
import { graphql } from 'gatsby'
import { isIE } from 'react-device-detect'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import localize from '../containers/localize'
import { GlobalDispatchContext, GlobalStateContext } from '../containers/GlobalContextProvider'
import Hero from '../components/home/Hero'
import FeaturedProjects from '../components/home/FeaturedProjects'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import Awards from '../components/about/Awards'
import NextPage from '../components/NextPage'
import NextPageTrigger from '../components/NextPageTrigger'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title {
        _type
        sl
        en
      }
      description {
        _type
        sl
        en
      }
      keywords
    }
    awards: allSanityAward(sort: { fields: [sort], order: ASC }) {
      edges {
        node {
          _type
          id
          title {
            _type
            sl
            en
          }
          image {
            asset {
              _id
              fixed(width: 300) {
                ...GatsbySanityImageFixed_noBase64
              }
            }
          }
        }
      }
    }
    featuredProjects: allSanityProject(
      filter: { featured: { eq: true } }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          _type
          id
          publishedAt
          featuredImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
              fluid(maxWidth: 2900, toFormat: JPG) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
              fluid(maxWidth: 2800) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
          awards {
            title {
              _type
              sl
              en
            }
            whiteImage {
              asset {
                _id
                fixed(width: 100) {
                  srcSet
                  src
                }
              }
            }
          }
          title {
            _type
            sl
            en
          }
          taglineWord {
            _type
            sl
            en
          }
          slug {
            _type
            sl {
              current
            }
            en {
              current
            }
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) return <GraphQLErrorList errors={errors} />

  const featuredProjects = (data || {}).featuredProjects
    ? mapEdgesToNodes(data.featuredProjects).filter(filterOutDocsWithoutSlugs)
    : []
  const awards = (data || {}).awards ? mapEdgesToNodes(data.awards) : []
  const site = (data || {}).site
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  const isTouch =
    typeof window !== 'undefined' ? window.matchMedia('(pointer: coarse)').matches : false

  const [isPageLoaded, setPageLoaded] = useState(false)
  const [isHeroLoaded, setHeroLoaded] = useState(false)
  const [isHeroAnimated, setHeroAnimated] = useState(false)
  const [fontsReady, setFontsReady] = useState(false)
  const [isHeroVisible, setHeroVisible] = useState(true)
  const [isNextPageTriggerShown, showNextPageTrigger] = useState(false)

  const locale = props.pageContext.locale

  const isSticky = !isIE

  const urlParams = {
    ufo: {
      name: 'ufo',
      value: true
    },
    props: {
      name: 'props',
      value: true
    },
    backgroundImages: {
      name: 'backgroundImages',
      value: false
    },
    sticky: {
      name: 'sticky',
      value: true
    },
    construction: {
      name: 'construction',
      value: true
    },
    images: {
      name: 'images',
      value: true
    },
    parallax: {
      name: 'parallax',
      value: true
    }
  }

  if (typeof window !== 'undefined') {
    const url = new URL(location.href)

    for (const key in urlParams) {
      if (urlParams.hasOwnProperty(key)) {
        const param = urlParams[key]
        const valueInUrl = url.searchParams.get(param.name)
        if (valueInUrl) {
          param.value = valueInUrl === 'yes'
        }
      }
    }
  }

  const taglines = {
    hero: {
      sl: 'Agencija 101',
      en: 'Agencija 101',
      variation: 'dark'
    },
    projects: {
      sl: 'Izbrani projekti',
      en: 'Featured projects',
      variation: 'light'
    },
    awards: {
      sl: 'Svetleče stvari',
      en: 'Shiny things',
      variation: 'dark'
    },
    about: {
      sl: 'O nas',
      en: 'About us'
    }
  }

  const sectionOffsets = []
  useEffect(() => {
    if (context.transitionState === 'overscrolled-top') {
      dispatch({
        type: 'SET_PAGE_CONTEXT_DATA',
        data: {
          ...props.pageContext,
          tagline: taglines.awards[locale],
          layout: 'normal',
          headerVariation: 'dark'
        }
      })
    } else {
      dispatch({
        type: 'SET_PAGE_CONTEXT_DATA',
        data: { ...props.pageContext, layout: 'fullPage' }
      })
    }

    if (typeof window !== 'undefined') {
      document.querySelectorAll('section').forEach(section => {
        if (!section.dataset.isoverscroll) {
          sectionOffsets.push({
            offset: section.offsetTop,
            height: section.offsetHeight,
            id: section.id
          })
        }
      })
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      if (typeof window !== 'undefined') window.removeEventListener('scroll', onScroll)
    }
  }, [])

  // Check if page is ready. // Fonts are loaded and hero assets are loaded.
  const { fontsLoaded } = context
  useEffect(() => {
    if (fontsLoaded) setFontsReady(true)
    if (fontsReady && isHeroLoaded) onPageLoaded()
  }, [fontsLoaded, fontsReady, isHeroLoaded])

  function onPageLoaded() {
    /**
     * If page was loaded through overscroll-top transition - user came from about and scrolled over top
     * If yes, we have to scroll page to the bottom and set tagline accordingly. Tagline is set into useEffect()[]
     */
    if (context.transitionState === 'overscrolled-top') {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight - window.innerHeight * 2.5)
        setTimeout(() => {
          dispatch({ type: 'SET_TRANSITION_STATE', state: 'overscrollEnter' })
          setPageLoaded(true)
        }, 100)
      }, 100)
    } else {
      setPageLoaded(true)
    }
  }

  let currentSection = ''
  let heroVisible = true
  let nextPageToggle = useRef()
  function onScroll() {
    const scrollY = window.scrollY

    if (scrollY > window.innerHeight * 1.5) {
      if (heroVisible) {
        setHeroVisible(false)
        heroVisible = false
      }
    } else {
      if (!heroVisible) {
        setHeroVisible(true)
        heroVisible = true
      }
    }

    if (scrollY > document.body.scrollHeight - window.innerHeight * 1.5) {
      if (!nextPageToggle.current) {
        showNextPageTrigger(true)
        nextPageToggle.current = true
      }
    } else {
      if (nextPageToggle.current) {
        showNextPageTrigger(false)
        nextPageToggle.current = false
      }
    }

    for (let i = 0; i < sectionOffsets.length; i++) {
      const { offset, id, height } = sectionOffsets[i]
      if (scrollY > offset && scrollY < offset + height) {
        const taglineId = id.includes('featured') ? 'projects' : id
        if (currentSection === taglineId || !taglines[taglineId]) continue
        const tagline = taglines[taglineId][locale]
        const variation = taglines[taglineId].variation

        dispatch({
          type: 'SET_TAGLINE',
          tagline
        })
        dispatch({
          type: 'SET_HEADER_VARIATION',
          variation
        })
        currentSection = taglineId
      }
    }
  }

  useEffect(() => {
    // Pause hero animations if menu opened
    if (context.menuOpened) {
      setHeroVisible(false)
    } else {
      setHeroVisible(true)
    }
  }, [context.menuOpened])

  /**
   * Called when hero is fully shown and functional
   */
  const heroReady = () => {
    if (
      context.transitionState !== 'overscrollEnter' &&
      context.transitionState !== 'overscrolled-top'
    ) {
      dispatch({ type: 'SET_PAGE_LAYOUT', layout: 'normal' })
      dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageEntering' })
    }
    setHeroAnimated(true)
  }

  /**
   * Called when all hero assets are loaded and ready to be shown
   */
  const heroLoaded = () => {
    setHeroLoaded(true)
  }

  return (
    <>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />

      <Hero
        onLoaded={heroLoaded}
        onReady={heroReady}
        pageLoaded={isPageLoaded}
        isVisible={isHeroVisible}
        locale={locale}
        pageSticky={isSticky}
        cachedImages={context.heroImagesCache}
        transitionStatus={props.transitionStatus}
      />

      {featuredProjects && (
        <FeaturedProjects
          projects={featuredProjects}
          showContent
          locale={locale}
          pageSticky={isSticky}
          parallax={false}
        />
      )}
      <NextPage
        title={locale === 'sl' ? 'Vsi projekti' : 'All projects'}
        link={locale === 'sl' ? '/projekti/' : '/en/projects/'}
        position="relative"
        arrowForward
        pageSticky={isSticky}
      />

      <Awards awards={awards} locale={locale} parallax={false} isSticky={isSticky} />

      <NextPageTrigger
        shown={isNextPageTriggerShown}
        enabled={isHeroAnimated}
        zIndex={19}
        title={locale === 'sl' ? 'O nas' : 'About us'}
        link={locale === 'sl' ? '/o-nas/' : '/en/about-us/'}
      ></NextPageTrigger>
    </>
  )
}

// We wrap Index page into localization component.
// This component will provide localized data from Sanity to this component.
export default localize(IndexPage)
