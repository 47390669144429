import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gsap, { Power1 } from 'gsap'
import styles from './Loader.module.css'
import ConstructionSVG from '../../animations/construction/construction.inline.svg'

export default class Loader extends Component {
  static propTypes = {
    pageLoaded: PropTypes.bool.isRequired,
    onConstructionCompleted: PropTypes.func.isRequired,
    locale: PropTypes.oneOf(['sl', 'en']).isRequired,
    skipAnimations: PropTypes.bool.isRequired,
    show: PropTypes.bool
  }

  constructor(props) {
    super(props)
    // Those are SVG paths ID, that gets animated/shown in loading sequence
    // Order of these elements matter
    this.elementIds = [
      'path-0',
      'path-1',
      'path-2',
      'path-3',
      'path-4',
      'path-5',
      'path-01',
      'path-02',
      'path-000',
      'path-001',
      'path-002',
      'path-003',
      'path-004',
      'path-005',
      'path-006',
      'path-007',
      'door',
      'mat',
      'window-02',
      'window-01',
      'window-00',
      'balcony',
      'window-1',
      'window-2',
      'window-3',
      'window-4',
      'window-5',
      'dog-hole'
    ]

    this.text = {
      sl: 'Samo trenutek, da zgradimo spletno stran ...',
      en: 'Just a moment, while we build our website ...'
    }

    this.ids = this.elementIds.map(id => '#' + id)
    this.state = {
      completed: false
    }
  }

  componentDidMount() {
    this.tween = gsap.to(this.ids, {
      duration: 0.2,
      opacity: 1,
      stagger: { amount: 10, ease: Power1.easeIn },
      onComplete: this.constructionCompleted.bind(this)
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageLoaded !== this.props.pageLoaded && this.props.pageLoaded) {
      // Page has loaded
      if (this.props.skipAnimations) this.tween.progress(1)
      else this.completeConstruction()
    }
  }

  completeConstruction() {
    this.tween.timeScale(6).play()
  }

  constructionCompleted() {
    this.setState({ completed: true })
    if (this.props.onConstructionCompleted) this.props.onConstructionCompleted()
  }

  render() {
    return (
      <div className={styles.root}>
        <ConstructionSVG></ConstructionSVG>

        {!this.props.skipAnimations && (
          <p className={`${styles.loaderText} ${this.state.completed ? styles.hide : ''}`}>
            {this.text[this.props.locale]}
          </p>
        )}
      </div>
    )
  }
}
