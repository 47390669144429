import React from 'react'
import PropTypes from 'prop-types'
import FeaturedProject from './FeaturedProject'
import FixedHeadline from './FixedHeadline'

function FeaturedProjects(props) {
  const headlineText = props.locale == 'sl' ? 'Tisti 1 % več' : 'That 1 % more'
  const generateLink = slug => (props.locale == 'sl' ? `/projekt/${slug}` : `/en/project/${slug}`)

  return (
    <>
      {props.pageSticky && <FixedHeadline text={headlineText} isSticky={props.pageSticky} />}
      {props.projects.map((project, index) => (
        <FeaturedProject
          {...project}
          link={generateLink(project.slug.current)}
          key={project.id}
          index={index}
          locale={props.locale}
          viewProjectText={props.locale == 'sl' ? 'Poglej projekt' : 'View project'}
          isSticky={props.pageSticky}
          headlineText={headlineText}
          parallax={props.parallax}
          awards={project.awards}
        />
      ))}
    </>
  )
}

FeaturedProjects.propTypes = {
  projects: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  pageSticky: PropTypes.bool.isRequired,
  parallax: PropTypes.bool
}

export default FeaturedProjects
