import React, { useRef, useEffect, useState } from 'react'
import styles from './Clouds.module.css'
import gsap from 'gsap'
function Clouds () {
  // Generate clouds according to window width
  // Set random x,y positions
  const containerRef = useRef()

  const settings = {
    constraints: {
      width: [10, 300],
      x: [200],
      y: [-10]
    },
    responsive: {
      0: {
        count: 4
      },
      650: {
        count: 6
      },
      1200: {
        count: 8
      },
      1600: {
        count: 10
      },
      2400: {
        count: 16
      },
      2900: {
        count: 18
      },
      20000: {
        count: 22
      }
    }
  }

  const [clouds, setClouds] = useState([])

  useEffect(() => {
    generateClouds(getCloudCount(window.innerWidth), settings.constraints)
  }, [])

  let cloudsGeneratedCount = 0
  const generatedClouds = []
  function generateClouds (count, constraints) {
    const container = {
      height: containerRef.current.offsetHeight,
      width: containerRef.current.offsetWidth
    }
    const cloud = getRandomCloud()
    generatedClouds.push(cloud)
    cloudsGeneratedCount++

    if (count === cloudsGeneratedCount) {
      setClouds(generatedClouds)
      setTimeout(() => {
        startAnimating(generatedClouds)
      }, 100)
      return true
    } else {
      generateClouds(count, constraints)
    }

    function getRandomCloud () {
      const cloudWidth = randomBetween(constraints.width[0], constraints.width[1])
      const position = {
        x: randomBetween(constraints.x[0], container.width + 100),
        y: randomBetween(constraints.y[0], container.height)
      }
      const cloud = {
        opacity: Math.random() + 0.5,
        width: cloudWidth,
        height: cloudWidth * 0.15,
        x: position.x,
        y: position.y,
        shouldAnimate: false
      }
      // I was thinking of checking overlapping clouds, but we will allow them to overalp for now
      // if (cloudOverlap(cloud, generatedClouds)) return getRandomCloud()
      return cloud
    }
  }

  // Set shouldAnimate property on clouds to true
  // We have to do that a little bit later so browser registers the start of animation
  function startAnimating () {
    // setClouds(clouds.map(cloud => ({ ...cloud, shouldAnimate: true })))
    gsap.to(containerRef.current.children, { x: '-50%', duration: 33, yoyoEase: 'none' })
  }

  function getCloudCount (windowWidth) {
    for (const [breakpoint, clouds] of Object.entries(settings.responsive)) {
      if (windowWidth <= breakpoint) {
        return clouds.count
      }
    }
  }

  function randomBetween (min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  return (
    <div className={styles.root} ref={containerRef}>
      {clouds &&
        clouds.map((cloud, index) => (
          <div
            className={styles.cloud}
            key={index}
            style={{
              width: cloud.width,
              height: cloud.height,
              left: cloud.x,
              top: cloud.y,
              opacity: cloud.opacity
            }}
          />
        ))}
    </div>
  )
}

export default Clouds
