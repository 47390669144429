import React from 'react'
import styles from './UfoSvg.module.css'
export default function UfoSvg () {
  return (
    <svg
      id='elvis-ufo'
      xmlns='http://www.w3.org/2000/svg'
      className={styles.root}
      viewBox='0 0 500 1440'
    >
      <g id='custom_1' data-name='custom – 1' clipPath='url(#clip-custom_1)'>
        <g id='whole-ufo' transform='translate(23 23)'>
          <g id='fire-outer' transform='translate(197.121 315.423)'>
            <g id='Group_1' data-name='Group 1' transform='translate(41.407 25.621)'>
              <path
                id='Path_1'
                data-name='Path 1'
                d='M0-24.844c41.326-.7,0,50.384,0,50.384S-41.326-25.54,0-24.844Z'
                fill='#ea3b42'
              />
            </g>
          </g>
          <g id='fire-inner' transform='translate(197.121 315.435)'>
            <g id='Group_2' data-name='Group 2' transform='translate(41.407 20.519)'>
              <path
                id='Path_2'
                data-name='Path 2'
                d='M0,16.427H0c-.269-.332-26.443-32.854,0-32.41C26.443-16.425.271,16.094,0,16.427Z'
                fill='#ffcd46'
              />
            </g>
          </g>
          <g id='ufo' transform='translate(118.786 179.536)'>
            <g id='Group_3' data-name='Group 3' transform='translate(119.743 117.615)'>
              <path
                id='Path_3'
                data-name='Path 3'
                d='M-45.1-13.145l11.332,20.5c2.068,3.237,15.12,5.79,33.768,5.79s31.7-2.791,33.764-5.792L45.1-13.145Z'
                fill='#dd8611'
              />
            </g>
            <g id='Group_4' data-name='Group 4' transform='translate(119.741 90.435)'>
              <path
                id='Path_4'
                data-name='Path 4'
                d='M-74.064-21.588-55.45,12.075C-52.063,17.4-30.623,21.588,0,21.588S52.061,17,55.449,12.075L74.064-21.588Z'
                fill='#897064'
              />
            </g>
            <g id='Group_5' data-name='Group 5' transform='translate(117.925 64.757)'>
              <path
                id='Path_5'
                data-name='Path 5'
                d='M-117.041-38.913l29.415,60.678C-82.272,31.36-48.394,38.913,0,38.913S82.272,30.65,87.627,21.766l29.414-60.678Z'
                fill='#db8511'
              />
            </g>
            <g id='Group_6' data-name='Group 6' transform='translate(117.925 23.142)'>
              <path
                id='Path_6'
                data-name='Path 6'
                d='M-117.843,0c0,12.738,52.762,23.062,117.844,23.062S117.843,12.736,117.843,0,65.084-23.061,0-23.061-117.843-12.736-117.843,0Z'
                fill='#ffb800'
              />
            </g>
            <g id='Group_7' data-name='Group 7' transform='translate(119.742 23.245)'>
              <path
                id='Path_7'
                data-name='Path 7'
                d='M-76.54,0C-76.54,9.005-42.271,16.3,0,16.3S76.54,9.007,76.54,0,42.272-16.3,0-16.3-76.54-9-76.54,0Z'
                fill='#897064'
              />
              <path
                id='Path_8'
                data-name='Path 8'
                d='M-76.54,0C-76.54,9.005-42.271,16.3,0,16.3S76.54,9.007,76.54,0,42.272-16.3,0-16.3-76.54-9-76.54,0Z'
                fill='#897064'
              />
            </g>
          </g>
          <g id='left-hand' transform='matrix(0.342, -0.94, 0.94, 0.342, 115.163, 185.506)'>
            <g id='Group_8' data-name='Group 8' transform='translate(49.695 64.926)'>
              <path id='Path_9' data-name='Path 9' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_9' data-name='Group 9' transform='translate(49.77 65.39)'>
              <path id='Path_10' data-name='Path 10' d='M0,0' fill='#1a1717' />
            </g>
            <g id='Group_10' data-name='Group 10' transform='translate(46.889 62.837)'>
              <path id='Path_11' data-name='Path 11' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_11' data-name='Group 11' transform='translate(76.407 65.052)'>
              <path id='Path_12' data-name='Path 12' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_12' data-name='Group 12' transform='translate(22.688 79.282)'>
              <path
                id='Path_13'
                data-name='Path 13'
                d='M-9.477-16.288v22.6H14.465a4.989,4.989,0,1,1,0,9.977H-14.464A4.989,4.989,0,0,1-19.453,11.3V-16.288Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_13' data-name='Group 13' transform='translate(8.194 60.323)'>
              <path
                id='Path_14'
                data-name='Path 14'
                d='M8.118,0A8.118,8.118,0,0,1,0,8.118,8.118,8.118,0,0,1-8.118,0,8.118,8.118,0,0,1,0-8.118,8.119,8.119,0,0,1,8.118,0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_14' data-name='Group 14' transform='translate(14.136 53.628)'>
              <path
                id='Path_15'
                data-name='Path 15'
                d='M-2.175,6.528A2.176,2.176,0,0,0,0,8.706,2.176,2.176,0,0,0,2.176,6.529h0V-6.53A2.176,2.176,0,0,0,0-8.706,2.175,2.175,0,0,0-2.175-6.531h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_15' data-name='Group 15' transform='translate(2.272 54.3)'>
              <path
                id='Path_16'
                data-name='Path 16'
                d='M-2.176,6.53A2.175,2.175,0,0,0,0,8.706H0A2.177,2.177,0,0,0,2.177,6.53V-6.53A2.177,2.177,0,0,0,0-8.706,2.176,2.176,0,0,0-2.176-6.53Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_16' data-name='Group 16' transform='translate(10.284 51.936)'>
              <path
                id='Path_17'
                data-name='Path 17'
                d='M-2.32,6.275A2.214,2.214,0,0,0,0,8.368,2.217,2.217,0,0,0,2.32,6.275V-6.276A2.216,2.216,0,0,0,0-8.368,2.213,2.213,0,0,0-2.32-6.276Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_17' data-name='Group 17' transform='translate(6.378 53.077)'>
              <path
                id='Path_18'
                data-name='Path 18'
                d='M-2.32,6.275A2.214,2.214,0,0,0,0,8.368,2.217,2.217,0,0,0,2.32,6.275V-6.276A2.216,2.216,0,0,0,0-8.368,2.213,2.213,0,0,0-2.32-6.276Z'
                fill='#39a95e'
              />
            </g>
            <g
              id='Group_18'
              data-name='Group 18'
              transform='matrix(0.866, -0.5, 0.5, 0.866, 15.031, 57.699)'
            >
              <path
                id='Path_19'
                data-name='Path 19'
                d='M-6.154,3.077a2.175,2.175,0,0,0,0,3.076h0a2.178,2.178,0,0,0,3.077,0l9.233-9.23a2.178,2.178,0,0,0,0-3.077,2.176,2.176,0,0,0-3.078,0h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_19' data-name='Group 19' transform='translate(62.485 65.544)'>
              <path id='Path_20' data-name='Path 20' d='M0,0' />
            </g>
          </g>
          <g id='right-hand' transform='translate(300.094 92.079) rotate(-179.999)'>
            <g id='Group_20' data-name='Group 20' transform='translate(49.149 -64.212)'>
              <path id='Path_21' data-name='Path 21' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_21' data-name='Group 21' transform='translate(49.223 -64.672)'>
              <path id='Path_22' data-name='Path 22' d='M0,0' fill='#1a1717' />
            </g>
            <g id='Group_22' data-name='Group 22' transform='translate(46.374 -62.147)'>
              <path id='Path_23' data-name='Path 23' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_23' data-name='Group 23' transform='translate(75.568 -64.337)'>
              <path id='Path_24' data-name='Path 24' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_24' data-name='Group 24' transform='translate(22.438 -78.41)'>
              <path
                id='Path_25'
                data-name='Path 25'
                d='M-9.373,16.109V-6.242H14.306a4.933,4.933,0,0,0,4.934-4.935,4.933,4.933,0,0,0-4.934-4.933H-14.3a4.934,4.934,0,0,0-4.934,4.934V16.109Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_25' data-name='Group 25' transform='translate(8.104 -59.66)'>
              <path
                id='Path_26'
                data-name='Path 26'
                d='M8.029,0A8.029,8.029,0,0,0,0-8.029,8.029,8.029,0,0,0-8.029,0,8.028,8.028,0,0,0,0,8.029,8.03,8.03,0,0,0,8.029,0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_26' data-name='Group 26' transform='translate(14.087 -54.194)'>
              <path
                id='Path_27'
                data-name='Path 27'
                d='M-2.151-6.456A2.152,2.152,0,0,1,0-8.61,2.152,2.152,0,0,1,2.152-6.458h0V6.458A2.152,2.152,0,0,1,0,8.61,2.151,2.151,0,0,1-2.151,6.459h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_27' data-name='Group 27' transform='translate(2.338 -54.741)'>
              <path
                id='Path_28'
                data-name='Path 28'
                d='M-2.152-6.458A2.151,2.151,0,0,1,0-8.61H0A2.154,2.154,0,0,1,2.153-6.458V6.458A2.154,2.154,0,0,1,0,8.61,2.152,2.152,0,0,1-2.152,6.458Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_28' data-name='Group 28' transform='translate(10.092 -51.85)'>
              <path
                id='Path_29'
                data-name='Path 29'
                d='M-2.3-6.206A2.19,2.19,0,0,1,0-8.276a2.192,2.192,0,0,1,2.3,2.07V6.207A2.192,2.192,0,0,1,0,8.276,2.189,2.189,0,0,1-2.295,6.207Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_29' data-name='Group 29' transform='translate(6.201 -53.082)'>
              <path
                id='Path_30'
                data-name='Path 30'
                d='M-2.3-6.206A2.19,2.19,0,0,1,0-8.276a2.192,2.192,0,0,1,2.3,2.07V6.207A2.192,2.192,0,0,1,0,8.276,2.189,2.189,0,0,1-2.295,6.207Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_30' data-name='Group 30' transform='translate(16.132 -59.658)'>
              <path
                id='Path_31'
                data-name='Path 31'
                d='M-6.086-3.044a2.151,2.151,0,0,1,0-3.042h0a2.154,2.154,0,0,1,3.043,0L6.088,3.042A2.152,2.152,0,0,1,3.044,6.086h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_31' data-name='Group 31' transform='translate(61.798 -64.824)'>
              <path id='Path_32' data-name='Path 32' d='M0,0' />
            </g>
          </g>
          <g id='body' transform='translate(176.421 92.078)'>
            <g id='Group_32' data-name='Group 32' transform='translate(64.413 78.975)'>
              <path
                id='Path_33'
                data-name='Path 33'
                d='M29.353-48.1H-29.355c-10.949,0-4.02,9.791-4.02,20.74,0,0,1.746,17.088,2.75,37.118s.436,37.267.436,37.267C-21.175,47.72-11.446,48.1-1.31,48.1c8.851,0,17.387-.289,25.411-.825L24.935,4.1l3.358-31.463C28.3-38.309,40.3-48.1,29.353-48.1Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_33' data-name='Group 33' transform='translate(49.149 64.212)'>
              <path
                id='Path_34'
                data-name='Path 34'
                d='M-5.941,0A5.941,5.941,0,0,0,0,5.941,5.941,5.941,0,0,0,5.941,0,5.941,5.941,0,0,0,0-5.941,5.941,5.941,0,0,0-5.941,0Z'
                fill='#fff'
              />
            </g>
            <g id='Group_34' data-name='Group 34' transform='translate(49.223 64.672)'>
              <path
                id='Path_35'
                data-name='Path 35'
                d='M-3.43,0A3.43,3.43,0,0,0,0,3.43,3.43,3.43,0,0,0,3.43,0,3.43,3.43,0,0,0,0-3.43,3.43,3.43,0,0,0-3.43,0Z'
                fill='#1a1717'
              />
            </g>
            <g id='Group_35' data-name='Group 35' transform='translate(46.374 62.147)'>
              <path
                id='Path_36'
                data-name='Path 36'
                d='M-1.276,0A1.276,1.276,0,0,0,0,1.276,1.276,1.276,0,0,0,1.276,0,1.276,1.276,0,0,0,0-1.276,1.276,1.276,0,0,0-1.276,0Z'
                fill='#fff'
              />
            </g>
            <g id='Group_36' data-name='Group 36' transform='translate(75.568 64.337)'>
              <path
                id='Path_37'
                data-name='Path 37'
                d='M-5.941,0A5.941,5.941,0,0,0,0,5.941,5.941,5.941,0,0,0,5.941,0,5.941,5.941,0,0,0,0-5.941,5.941,5.941,0,0,0-5.941,0Z'
                fill='#fff'
              />
            </g>
            <g id='Group_37' data-name='Group 37' transform='translate(75.642 64.797)'>
              <path
                id='Path_38'
                data-name='Path 38'
                d='M-3.43,0A3.43,3.43,0,0,0,0,3.43,3.431,3.431,0,0,0,3.43,0,3.43,3.43,0,0,0,0-3.43,3.43,3.43,0,0,0-3.43,0Z'
                fill='#1a1717'
              />
            </g>
            <g id='Group_38' data-name='Group 38' transform='translate(72.793 62.272)'>
              <path
                id='Path_39'
                data-name='Path 39'
                d='M-1.276,0A1.276,1.276,0,0,0,0,1.276,1.276,1.276,0,0,0,1.276,0,1.276,1.276,0,0,0,0-1.276,1.276,1.276,0,0,0-1.276,0Z'
                fill='#fff'
              />
            </g>
            <g id='Group_39' data-name='Group 39' transform='translate(61.798 64.824)'>
              <path
                id='Path_40'
                data-name='Path 40'
                d='M15.6,8.734A16.635,16.635,0,0,1,5.177,5.02a9.992,9.992,0,0,1-3.16-5.3C2-.332,1.988-.387,1.974-.441,1.781-1.214,1.615-1.878.153-1.878h-.1c-1.113.013-1.653.145-1.838,1.3A8.306,8.306,0,0,1-4.554,4.41,16.58,16.58,0,0,1-10.3,7.828a13.984,13.984,0,0,1-4.5.694h-.14a10.017,10.017,0,0,1-7.132-2.756,10.212,10.212,0,0,1-2.885-7.354,7.172,7.172,0,0,1,1.647-4.83c1.245-1.366,3.191-2.112,5.949-2.3.905-.035,1.838-.05,2.84-.05,2.611,0,5.28.115,7.862.227,2.192.094,4.457.192,6.683.221H.949c2.233,0,4.416-.1,6.527-.188h.08c2.13-.093,4.333-.19,6.664-.19.4,0,.8,0,1.187.009,2.838.072,5.985.4,7.808,2.316a6.716,6.716,0,0,1,1.528,5.324,10.657,10.657,0,0,1-2.43,6.722A8.676,8.676,0,0,1,15.6,8.734ZM-13.588-7.344a28.915,28.915,0,0,0-4.547.368,7.166,7.166,0,0,0-3.593,1.643,5.542,5.542,0,0,0-1.525,3.92,9.149,9.149,0,0,0,2.4,6.774,8.2,8.2,0,0,0,5.9,2.179A14.673,14.673,0,0,0-8.158,5.5C-5.184,3.559-3.341,1.017-3.1-1.475a4.73,4.73,0,0,0-1.122-3.51A5.561,5.561,0,0,0-6.935-6.56a29.481,29.481,0,0,0-6.653-.784Zm26.756.077A20.274,20.274,0,0,0,6.647-6.4,5.315,5.315,0,0,0,3.493-3.46a5.889,5.889,0,0,0,.8,4.769A12.878,12.878,0,0,0,15.073,7.49h.264a7.765,7.765,0,0,0,4.136-1.243,7.334,7.334,0,0,0,2.394-2.385,10.088,10.088,0,0,0,1.05-4.351,8.587,8.587,0,0,0-.76-3.811c-.98-1.715-2.727-2.582-5.858-2.892h-.157c-.965-.039-1.971-.075-2.977-.075Zm-16.882.289h0L-1.59-3.659a8.1,8.1,0,0,0,1.715.2,5.733,5.733,0,0,0,1.633-.227l2.194-3.32c-1.328.111-2.675.168-4,.168-1.205,0-2.437-.047-3.657-.137Z'
              />
            </g>
            <g id='Group_40' data-name='Group 40' transform='translate(48.728 64.924)'>
              <path
                id='Path_41'
                data-name='Path 41'
                d='M-1.878,7.446A14.671,14.671,0,0,0,4.913,5.4c2.8-1.823,4.8-4.32,5.055-6.974a4.75,4.75,0,0,0-3.83-5.085,28.439,28.439,0,0,0-11.2-.426C-8-6.455-10-5.185-10.18-1.512c-.075,4.764,2.4,8.9,8.3,8.952Z'
                fill='rgba(0,0,0,0.5)'
              />
            </g>
            <g id='Group_41' data-name='Group 41' transform='translate(74.481 64.92)'>
              <path
                id='Path_42'
                data-name='Path 42'
                d='M-6.029-6.5c-4.214,1.83-3.837,5.034-2.35,7.7A12.939,12.939,0,0,0,2.4,7.389,7.9,7.9,0,0,0,9.193,3.766,9.717,9.717,0,0,0,9.483-4.4C8.524-6.091,6.788-6.98,3.624-7.292A27.455,27.455,0,0,0-6.033-6.5Z'
                fill='rgba(0,0,0,0.5)'
              />
            </g>
            <g id='Group_42' data-name='Group 42' transform='translate(61.127 44.569)'>
              <path
                id='Path_43'
                data-name='Path 43'
                d='M-27.534,41.94l8.06,1.827C-26.727,26.6-31.74,4.912-25.6-8.211,17.046,10.209.6-8.548,27.045-9.661,31.385,2.677,30.4,26.2,20.222,44.5l7.937-2.573c4.987-33.62,27.624-48.638,9.457-71.412-6-6.493-12.465-10.207-23.692-5.551-24.48,10.152-23.746-9.46-46.616.147C-55.783-22.209-30.256.555-27.54,41.935Z'
              />
            </g>
            <g id='Group_43' data-name='Group 43' transform='translate(64.983 47.739)'>
              <path
                id='Path_44'
                data-name='Path 44'
                d='M-5.71-5.632C-.76-2.882.293.983-2.2,7.435,2.8,4.786,5.7-2.83,3.478-7.436Z'
              />
            </g>
          </g>
          <g id='mouth' transform='translate(176.421 92.078)'>
            <g id='Group_44' data-name='Group 44' transform='translate(62.797 83.191)'>
              <path
                id='Path_45'
                data-name='Path 45'
                d='M8.851-.2C2.592,2.395-4.143-3.852-11.074-2-2.762-.65.712,3.852,6.554,3.362,10.808,2.455,11.074.045,8.851-.2Z'
                fill='#fff'
              />
            </g>
          </g>
          <g id='glass' transform='translate(162.208 90.234)' opacity='0.25'>
            <g id='Group_45' data-name='Group 45' transform='translate(76.541 61.819)'>
              <path
                id='Path_46'
                data-name='Path 46'
                d='M5.887-61.819H-5.887C-44.909-61.819-76.54-33.775-76.54.823V51.7C-76.54,60.705-42.271,68,0,68s76.539-7.3,76.539-16.3V.819C76.538-33.775,44.908-61.819,5.887-61.819Z'
                fill='#ccc'
              />
            </g>
            <g id='Group_46' data-name='Group 46' transform='translate(43.358 59.913)'>
              <path
                id='Path_47'
                data-name='Path 47'
                d='M-15.092,8.669c0-31.7,23.974-58.435,56.729-66.73q-1.639-.07-3.3-.072H26.914c-37.86,0-68.552,27.21-68.552,60.776V52.2c0,4.866,10.319,9.22,26.545,12.121Z'
                fill='#fff'
              />
            </g>
          </g>
        </g>
        <g id='Group_119' data-name='Group 119' transform='translate(22 56)'>
          <g
            id='right3'
            className={styles.hide}
            transform='translate(266.528 334.716) rotate(-143.967)'
          >
            <g id='Group_85' data-name='Group 85' transform='translate(50.298 65.714)'>
              <path id='Path_76' data-name='Path 76' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_86' data-name='Group 86' transform='translate(50.374 66.184)'>
              <path id='Path_77' data-name='Path 77' d='M0,0' fill='#1a1717' />
            </g>
            <g id='Group_87' data-name='Group 87' transform='translate(47.458 63.6)'>
              <path id='Path_78' data-name='Path 78' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_88' data-name='Group 88' transform='translate(77.335 65.842)'>
              <path id='Path_79' data-name='Path 79' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_89' data-name='Group 89' transform='translate(22.963 80.244)'>
              <path
                id='Path_80'
                data-name='Path 80'
                d='M-9.592-16.486S-9.69-3.741-9.936.658s-9.769,5.257-9.7-1.4c.041-4.048-.052-15.746-.052-15.746Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_90' data-name='Group 90' transform='translate(8.294 61.055)'>
              <path
                id='Path_81'
                data-name='Path 81'
                d='M8.217,0A8.217,8.217,0,0,1,0,8.217,8.216,8.216,0,0,1-8.217,0,8.216,8.216,0,0,1,0-8.217,8.218,8.218,0,0,1,8.217,0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_91' data-name='Group 91' transform='translate(14.337 55.051)'>
              <path
                id='Path_82'
                data-name='Path 82'
                d='M-2.2,6.607A2.2,2.2,0,0,0,0,8.811a2.2,2.2,0,0,0,2.2-2.2h0V-6.609A2.2,2.2,0,0,0,0-8.811a2.2,2.2,0,0,0-2.2,2.2h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_92' data-name='Group 92' transform='translate(2.176 57.489) rotate(-10)'>
              <path
                id='Path_83'
                data-name='Path 83'
                d='M-2.2,6.609A2.2,2.2,0,0,0,0,8.811H0a2.2,2.2,0,0,0,2.2-2.2V-6.609A2.2,2.2,0,0,0,0-8.811a2.2,2.2,0,0,0-2.2,2.2Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_93' data-name='Group 93' transform='translate(10.409 52.567)'>
              <path
                id='Path_84'
                data-name='Path 84'
                d='M-2.349,6.351A2.241,2.241,0,0,0,0,8.47a2.244,2.244,0,0,0,2.35-2.119v-12.7A2.243,2.243,0,0,0,0-8.469,2.24,2.24,0,0,0-2.349-6.352Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_94' data-name='Group 94' transform='translate(5.874 53.974)'>
              <path
                id='Path_85'
                data-name='Path 85'
                d='M-2.349,6.351A2.241,2.241,0,0,0,0,8.47a2.244,2.244,0,0,0,2.35-2.119v-12.7A2.243,2.243,0,0,0,0-8.469,2.24,2.24,0,0,0-2.349-6.352Z'
                fill='#39a95e'
              />
            </g>
            <g
              id='Group_95'
              data-name='Group 95'
              transform='matrix(1, -0.027, 0.027, 1, 15.644, 60.567)'
            >
              <path
                id='Path_86'
                data-name='Path 86'
                d='M-6.229,3.115a2.2,2.2,0,0,0,0,3.113h0a2.2,2.2,0,0,0,3.114,0L6.23-3.113a2.2,2.2,0,0,0,0-3.114,2.2,2.2,0,0,0-3.115,0h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_96' data-name='Group 96' transform='translate(63.244 66.339)'>
              <path id='Path_87' data-name='Path 87' d='M0,0' />
            </g>
          </g>
          <g
            id='left3'
            className={styles.hide}
            transform='translate(191.327 337.189) rotate(175.331)'
          >
            <g id='Group_98' data-name='Group 98' transform='translate(50.298 65.714)'>
              <path id='Path_88' data-name='Path 88' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_99' data-name='Group 99' transform='translate(50.375 66.184)'>
              <path id='Path_89' data-name='Path 89' d='M0,0' fill='#1a1717' />
            </g>
            <g id='Group_100' data-name='Group 100' transform='translate(47.459 63.601)'>
              <path id='Path_90' data-name='Path 90' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_101' data-name='Group 101' transform='translate(77.335 65.842)'>
              <path id='Path_91' data-name='Path 91' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_102' data-name='Group 102' transform='translate(22.963 80.244)'>
              <path
                id='Path_92'
                data-name='Path 92'
                d='M-9.592-16.486S-9.69-3.741-9.936.658s-9.769,5.257-9.7-1.4c.041-4.048-.052-15.746-.052-15.746Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_103' data-name='Group 103' transform='translate(8.294 61.055)'>
              <path
                id='Path_93'
                data-name='Path 93'
                d='M8.217,0A8.217,8.217,0,0,1,0,8.217,8.216,8.216,0,0,1-8.217,0,8.216,8.216,0,0,1,0-8.217,8.218,8.218,0,0,1,8.217,0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_104' data-name='Group 104' transform='translate(14.337 55.051)'>
              <path
                id='Path_94'
                data-name='Path 94'
                d='M-2.2,6.607A2.2,2.2,0,0,0,0,8.811a2.2,2.2,0,0,0,2.2-2.2h0V-6.609A2.2,2.2,0,0,0,0-8.811a2.2,2.2,0,0,0-2.2,2.2V6.607Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_105' data-name='Group 105' transform='translate(2.176 57.489) rotate(-10)'>
              <path
                id='Path_95'
                data-name='Path 95'
                d='M-2.2,6.609A2.2,2.2,0,0,0,0,8.811H0a2.2,2.2,0,0,0,2.2-2.2V-6.609A2.2,2.2,0,0,0,0-8.811a2.2,2.2,0,0,0-2.2,2.2Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_106' data-name='Group 106' transform='translate(10.409 52.567)'>
              <path
                id='Path_96'
                data-name='Path 96'
                d='M-2.349,6.352A2.241,2.241,0,0,0,0,8.47a2.244,2.244,0,0,0,2.35-2.119v-12.7A2.243,2.243,0,0,0,0-8.469,2.24,2.24,0,0,0-2.349-6.352Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_107' data-name='Group 107' transform='translate(5.874 53.974)'>
              <path
                id='Path_97'
                data-name='Path 97'
                d='M-2.349,6.352A2.241,2.241,0,0,0,0,8.47a2.244,2.244,0,0,0,2.35-2.119v-12.7A2.243,2.243,0,0,0,0-8.469,2.24,2.24,0,0,0-2.349-6.352Z'
                fill='#39a95e'
              />
            </g>
            <g
              id='Group_108'
              data-name='Group 108'
              transform='matrix(1, -0.027, 0.027, 1, 15.644, 60.567)'
            >
              <path
                id='Path_98'
                data-name='Path 98'
                d='M-6.229,3.115a2.2,2.2,0,0,0,0,3.113h0a2.2,2.2,0,0,0,3.114,0L6.23-3.113a2.2,2.2,0,0,0,0-3.114,2.2,2.2,0,0,0-3.115,0h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_109' data-name='Group 109' transform='translate(63.244 66.34)'>
              <path id='Path_99' data-name='Path 99' d='M0,0' />
            </g>
          </g>
        </g>
        <g id='Group_120' data-name='Group 120' transform='translate(21 56)'>
          <g
            id='right2'
            className={styles.hide}
            transform='translate(249.528 334.716) rotate(-143.967)'
          >
            <g id='Group_85-2' data-name='Group 85' transform='translate(50.298 65.714)'>
              <path id='Path_76-2' data-name='Path 76' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_86-2' data-name='Group 86' transform='translate(50.374 66.184)'>
              <path id='Path_77-2' data-name='Path 77' d='M0,0' fill='#1a1717' />
            </g>
            <g id='Group_87-2' data-name='Group 87' transform='translate(47.458 63.6)'>
              <path id='Path_78-2' data-name='Path 78' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_88-2' data-name='Group 88' transform='translate(77.335 65.842)'>
              <path id='Path_79-2' data-name='Path 79' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_89-2' data-name='Group 89' transform='translate(22.963 80.244)'>
              <path
                id='Path_80-2'
                data-name='Path 80'
                d='M-9.592-16.486S-9.69-3.741-9.936.658s-9.769,5.257-9.7-1.4c.041-4.048-.052-15.746-.052-15.746Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_90-2' data-name='Group 90' transform='translate(8.294 61.055)'>
              <path
                id='Path_81-2'
                data-name='Path 81'
                d='M8.217,0A8.217,8.217,0,0,1,0,8.217,8.216,8.216,0,0,1-8.217,0,8.216,8.216,0,0,1,0-8.217,8.218,8.218,0,0,1,8.217,0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_91-2' data-name='Group 91' transform='translate(14.337 55.051)'>
              <path
                id='Path_82-2'
                data-name='Path 82'
                d='M-2.2,6.607A2.2,2.2,0,0,0,0,8.811a2.2,2.2,0,0,0,2.2-2.2h0V-6.609A2.2,2.2,0,0,0,0-8.811a2.2,2.2,0,0,0-2.2,2.2h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_92-2' data-name='Group 92' transform='translate(2.176 57.489) rotate(-10)'>
              <path
                id='Path_83-2'
                data-name='Path 83'
                d='M-2.2,6.609A2.2,2.2,0,0,0,0,8.811H0a2.2,2.2,0,0,0,2.2-2.2V-6.609A2.2,2.2,0,0,0,0-8.811a2.2,2.2,0,0,0-2.2,2.2Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_93-2' data-name='Group 93' transform='translate(10.409 52.567)'>
              <path
                id='Path_84-2'
                data-name='Path 84'
                d='M-2.349,6.351A2.241,2.241,0,0,0,0,8.47a2.244,2.244,0,0,0,2.35-2.119v-12.7A2.243,2.243,0,0,0,0-8.469,2.24,2.24,0,0,0-2.349-6.352Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_94-2' data-name='Group 94' transform='translate(5.874 53.974)'>
              <path
                id='Path_85-2'
                data-name='Path 85'
                d='M-2.349,6.351A2.241,2.241,0,0,0,0,8.47a2.244,2.244,0,0,0,2.35-2.119v-12.7A2.243,2.243,0,0,0,0-8.469,2.24,2.24,0,0,0-2.349-6.352Z'
                fill='#39a95e'
              />
            </g>
            <g
              id='Group_95-2'
              data-name='Group 95'
              transform='matrix(1, -0.027, 0.027, 1, 15.644, 60.567)'
            >
              <path
                id='Path_86-2'
                data-name='Path 86'
                d='M-6.229,3.115a2.2,2.2,0,0,0,0,3.113h0a2.2,2.2,0,0,0,3.114,0L6.23-3.113a2.2,2.2,0,0,0,0-3.114,2.2,2.2,0,0,0-3.115,0h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_96-2' data-name='Group 96' transform='translate(63.244 66.339)'>
              <path id='Path_87-2' data-name='Path 87' d='M0,0' />
            </g>
          </g>
          <g
            id='left2'
            className={styles.hide}
            transform='translate(200.327 337.189) rotate(175.331)'
          >
            <g id='Group_98-2' data-name='Group 98' transform='translate(50.298 65.714)'>
              <path id='Path_88-2' data-name='Path 88' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_99-2' data-name='Group 99' transform='translate(50.375 66.184)'>
              <path id='Path_89-2' data-name='Path 89' d='M0,0' fill='#1a1717' />
            </g>
            <g id='Group_100-2' data-name='Group 100' transform='translate(47.459 63.601)'>
              <path id='Path_90-2' data-name='Path 90' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_101-2' data-name='Group 101' transform='translate(77.335 65.842)'>
              <path id='Path_91-2' data-name='Path 91' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_102-2' data-name='Group 102' transform='translate(22.963 80.244)'>
              <path
                id='Path_92-2'
                data-name='Path 92'
                d='M-9.592-16.486S-9.69-3.741-9.936.658s-9.769,5.257-9.7-1.4c.041-4.048-.052-15.746-.052-15.746Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_103-2' data-name='Group 103' transform='translate(8.294 61.055)'>
              <path
                id='Path_93-2'
                data-name='Path 93'
                d='M8.217,0A8.217,8.217,0,0,1,0,8.217,8.216,8.216,0,0,1-8.217,0,8.216,8.216,0,0,1,0-8.217,8.218,8.218,0,0,1,8.217,0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_104-2' data-name='Group 104' transform='translate(14.337 55.051)'>
              <path
                id='Path_94-2'
                data-name='Path 94'
                d='M-2.2,6.607A2.2,2.2,0,0,0,0,8.811a2.2,2.2,0,0,0,2.2-2.2h0V-6.609A2.2,2.2,0,0,0,0-8.811a2.2,2.2,0,0,0-2.2,2.2V6.607Z'
                fill='#39a95e'
              />
            </g>
            <g
              id='Group_105-2'
              data-name='Group 105'
              transform='translate(2.176 57.489) rotate(-10)'
            >
              <path
                id='Path_95-2'
                data-name='Path 95'
                d='M-2.2,6.609A2.2,2.2,0,0,0,0,8.811H0a2.2,2.2,0,0,0,2.2-2.2V-6.609A2.2,2.2,0,0,0,0-8.811a2.2,2.2,0,0,0-2.2,2.2Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_106-2' data-name='Group 106' transform='translate(10.409 52.567)'>
              <path
                id='Path_96-2'
                data-name='Path 96'
                d='M-2.349,6.352A2.241,2.241,0,0,0,0,8.47a2.244,2.244,0,0,0,2.35-2.119v-12.7A2.243,2.243,0,0,0,0-8.469,2.24,2.24,0,0,0-2.349-6.352Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_107-2' data-name='Group 107' transform='translate(5.874 53.974)'>
              <path
                id='Path_97-2'
                data-name='Path 97'
                d='M-2.349,6.352A2.241,2.241,0,0,0,0,8.47a2.244,2.244,0,0,0,2.35-2.119v-12.7A2.243,2.243,0,0,0,0-8.469,2.24,2.24,0,0,0-2.349-6.352Z'
                fill='#39a95e'
              />
            </g>
            <g
              id='Group_108-2'
              data-name='Group 108'
              transform='matrix(1, -0.027, 0.027, 1, 15.644, 60.567)'
            >
              <path
                id='Path_98-2'
                data-name='Path 98'
                d='M-6.229,3.115a2.2,2.2,0,0,0,0,3.113h0a2.2,2.2,0,0,0,3.114,0L6.23-3.113a2.2,2.2,0,0,0,0-3.114,2.2,2.2,0,0,0-3.115,0h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_109-2' data-name='Group 109' transform='translate(63.244 66.34)'>
              <path id='Path_99-2' data-name='Path 99' d='M0,0' />
            </g>
          </g>
        </g>
        <g id='Group_121' data-name='Group 121' transform='translate(21 56)'>
          <g
            id='right1'
            className={styles.hide}
            transform='translate(262.528 334.716) rotate(-143.967)'
          >
            <g id='Group_85-3' data-name='Group 85' transform='translate(50.298 65.714)'>
              <path id='Path_76-3' data-name='Path 76' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_86-3' data-name='Group 86' transform='translate(50.374 66.184)'>
              <path id='Path_77-3' data-name='Path 77' d='M0,0' fill='#1a1717' />
            </g>
            <g id='Group_87-3' data-name='Group 87' transform='translate(47.458 63.6)'>
              <path id='Path_78-3' data-name='Path 78' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_88-3' data-name='Group 88' transform='translate(77.335 65.842)'>
              <path id='Path_79-3' data-name='Path 79' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_89-3' data-name='Group 89' transform='translate(22.963 80.244)'>
              <path
                id='Path_80-3'
                data-name='Path 80'
                d='M-9.592-16.486S-9.69-3.741-9.936.658s-9.769,5.257-9.7-1.4c.041-4.048-.052-15.746-.052-15.746Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_90-3' data-name='Group 90' transform='translate(8.294 61.055)'>
              <path
                id='Path_81-3'
                data-name='Path 81'
                d='M8.217,0A8.217,8.217,0,0,1,0,8.217,8.216,8.216,0,0,1-8.217,0,8.216,8.216,0,0,1,0-8.217,8.218,8.218,0,0,1,8.217,0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_91-3' data-name='Group 91' transform='translate(14.337 55.051)'>
              <path
                id='Path_82-3'
                data-name='Path 82'
                d='M-2.2,6.607A2.2,2.2,0,0,0,0,8.811a2.2,2.2,0,0,0,2.2-2.2h0V-6.609A2.2,2.2,0,0,0,0-8.811a2.2,2.2,0,0,0-2.2,2.2h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_92-3' data-name='Group 92' transform='translate(2.176 57.489) rotate(-10)'>
              <path
                id='Path_83-3'
                data-name='Path 83'
                d='M-2.2,6.609A2.2,2.2,0,0,0,0,8.811H0a2.2,2.2,0,0,0,2.2-2.2V-6.609A2.2,2.2,0,0,0,0-8.811a2.2,2.2,0,0,0-2.2,2.2Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_93-3' data-name='Group 93' transform='translate(10.409 52.567)'>
              <path
                id='Path_84-3'
                data-name='Path 84'
                d='M-2.349,6.351A2.241,2.241,0,0,0,0,8.47a2.244,2.244,0,0,0,2.35-2.119v-12.7A2.243,2.243,0,0,0,0-8.469,2.24,2.24,0,0,0-2.349-6.352Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_94-3' data-name='Group 94' transform='translate(5.874 53.974)'>
              <path
                id='Path_85-3'
                data-name='Path 85'
                d='M-2.349,6.351A2.241,2.241,0,0,0,0,8.47a2.244,2.244,0,0,0,2.35-2.119v-12.7A2.243,2.243,0,0,0,0-8.469,2.24,2.24,0,0,0-2.349-6.352Z'
                fill='#39a95e'
              />
            </g>
            <g
              id='Group_95-3'
              data-name='Group 95'
              transform='matrix(1, -0.027, 0.027, 1, 15.644, 60.567)'
            >
              <path
                id='Path_86-3'
                data-name='Path 86'
                d='M-6.229,3.115a2.2,2.2,0,0,0,0,3.113h0a2.2,2.2,0,0,0,3.114,0L6.23-3.113a2.2,2.2,0,0,0,0-3.114,2.2,2.2,0,0,0-3.115,0h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_96-3' data-name='Group 96' transform='translate(63.244 66.339)'>
              <path id='Path_87-3' data-name='Path 87' d='M0,0' />
            </g>
          </g>
          <g
            id='left1'
            className={styles.hide}
            transform='translate(187.327 337.189) rotate(175.331)'
          >
            <g id='Group_98-3' data-name='Group 98' transform='translate(50.298 65.714)'>
              <path id='Path_88-3' data-name='Path 88' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_99-3' data-name='Group 99' transform='translate(50.375 66.184)'>
              <path id='Path_89-3' data-name='Path 89' d='M0,0' fill='#1a1717' />
            </g>
            <g id='Group_100-3' data-name='Group 100' transform='translate(47.459 63.601)'>
              <path id='Path_90-3' data-name='Path 90' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_101-3' data-name='Group 101' transform='translate(77.335 65.842)'>
              <path id='Path_91-3' data-name='Path 91' d='M0,0' fill='#fff' />
            </g>
            <g id='Group_102-3' data-name='Group 102' transform='translate(22.963 80.244)'>
              <path
                id='Path_92-3'
                data-name='Path 92'
                d='M-9.592-16.486S-9.69-3.741-9.936.658s-9.769,5.257-9.7-1.4c.041-4.048-.052-15.746-.052-15.746Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_103-3' data-name='Group 103' transform='translate(8.294 61.055)'>
              <path
                id='Path_93-3'
                data-name='Path 93'
                d='M8.217,0A8.217,8.217,0,0,1,0,8.217,8.216,8.216,0,0,1-8.217,0,8.216,8.216,0,0,1,0-8.217,8.218,8.218,0,0,1,8.217,0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_104-3' data-name='Group 104' transform='translate(14.337 55.051)'>
              <path
                id='Path_94-3'
                data-name='Path 94'
                d='M-2.2,6.607A2.2,2.2,0,0,0,0,8.811a2.2,2.2,0,0,0,2.2-2.2h0V-6.609A2.2,2.2,0,0,0,0-8.811a2.2,2.2,0,0,0-2.2,2.2V6.607Z'
                fill='#39a95e'
              />
            </g>
            <g
              id='Group_105-3'
              data-name='Group 105'
              transform='translate(2.176 57.489) rotate(-10)'
            >
              <path
                id='Path_95-3'
                data-name='Path 95'
                d='M-2.2,6.609A2.2,2.2,0,0,0,0,8.811H0a2.2,2.2,0,0,0,2.2-2.2V-6.609A2.2,2.2,0,0,0,0-8.811a2.2,2.2,0,0,0-2.2,2.2Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_106-3' data-name='Group 106' transform='translate(10.409 52.567)'>
              <path
                id='Path_96-3'
                data-name='Path 96'
                d='M-2.349,6.352A2.241,2.241,0,0,0,0,8.47a2.244,2.244,0,0,0,2.35-2.119v-12.7A2.243,2.243,0,0,0,0-8.469,2.24,2.24,0,0,0-2.349-6.352Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_107-3' data-name='Group 107' transform='translate(5.874 53.974)'>
              <path
                id='Path_97-3'
                data-name='Path 97'
                d='M-2.349,6.352A2.241,2.241,0,0,0,0,8.47a2.244,2.244,0,0,0,2.35-2.119v-12.7A2.243,2.243,0,0,0,0-8.469,2.24,2.24,0,0,0-2.349-6.352Z'
                fill='#39a95e'
              />
            </g>
            <g
              id='Group_108-3'
              data-name='Group 108'
              transform='matrix(1, -0.027, 0.027, 1, 15.644, 60.567)'
            >
              <path
                id='Path_98-3'
                data-name='Path 98'
                d='M-6.229,3.115a2.2,2.2,0,0,0,0,3.113h0a2.2,2.2,0,0,0,3.114,0L6.23-3.113a2.2,2.2,0,0,0,0-3.114,2.2,2.2,0,0,0-3.115,0h0Z'
                fill='#39a95e'
              />
            </g>
            <g id='Group_109-3' data-name='Group 109' transform='translate(63.244 66.34)'>
              <path id='Path_99-3' data-name='Path 99' d='M0,0' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
