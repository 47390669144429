import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import SanityImage from '../SanityImage'
import styles from './Awards.module.css'

function Awards({ awards, locale, parallax, isSticky, endParallax, isOverscroll }) {
  const text = {
    sl: {
      title: 'Osvojili smo 101 nagrado',
      subtitle: 'približno'
    },
    en: {
      title: 'We won 101 awards',
      subtitle: 'approximately'
    }
  }

  const wrapper = useRef()
  const tagline = useRef()

  let parallaxStart, parallaxEnd
  const taglineParallax = { startX: -30, endX: 30 }
  const distance = Math.abs(taglineParallax.startX) + taglineParallax.endX
  useEffect(() => {
    if (parallax) {
      parallaxStart = wrapper.current.offsetTop - wrapper.current.offsetHeight
      parallaxEnd = wrapper.current.offsetTop + wrapper.current.offsetHeight * 2
      gsap.set(tagline.current, { left: taglineParallax.startX })
      window.addEventListener('scroll', onScroll)
      window.addEventListener('resize', onResize)
    }

    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onResize)
    }
  }, [])

  useEffect(() => {
    if (endParallax) {
      gsap.set(tagline.current, { left: taglineParallax.startX, x: taglineParallax.endX / 2 })
    }
  }, [endParallax])

  function onResize() {
    if (wrapper && parallax) {
      parallaxStart = wrapper.current.offsetTop - wrapper.current.offsetHeight
      parallaxEnd = wrapper.current.offsetTop + wrapper.current.offsetHeight * 2
    }
  }

  function onScroll() {
    if (window.scrollY > parallaxStart && window.scrollY < parallaxEnd) {
      const delta = window.scrollY - parallaxStart
      // Normalizirat vrednosti, da dobiš od yStart do yEnd
      const deltaNormal = delta / (parallaxEnd - parallaxStart)
      gsap.to(tagline.current, { x: distance * deltaNormal, duration: 0.6 })
    }
  }

  return (
    <section
      className={`${styles.root} ${isSticky ? styles.sticky : ''}`}
      id="awards"
      ref={wrapper}
      data-isoverscroll={isOverscroll}
    >
      <div className={styles.content}>
        <div>
          <div className={styles.titles}>
            <h2 className={styles.title}>{text[locale].title}</h2>
            <h3 className={styles.subtitle} ref={tagline}>
              {text[locale].subtitle}
            </h3>
          </div>
          <div className={styles.awardsWrap}>
            <div className={styles.list}>
              {awards.map((award, index) => (
                <div className={styles.award} key={index}>
                  <SanityImage image={award.image} width={200} loading="lazy" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Awards.propTypes = {
  awards: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  parallax: PropTypes.bool,
  isSticky: PropTypes.bool,
  endParallax: PropTypes.bool,
  isOverscroll: PropTypes.bool
}

export default Awards
