import React, { useEffect, useContext, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'
import styles from './FeaturedProject.module.css'
import arrows from '../../images/fwd.svg'
import LinkTransition from '../navigation/LinkTransition'
import { GlobalDispatchContext, GlobalStateContext } from '../../containers/GlobalContextProvider'
import { clearDebouncedResize, debouncedResize } from '../../lib/helpers'
import SanityImage from '../SanityImage'

function FeaturedProject(props) {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  const wrapper = useRef()
  const tagline = useRef()

  let parallaxStart, parallaxEnd
  const parallaxLength = 30
  const taglineParallax = { startY: -parallaxLength, endY: parallaxLength }
  const distance = Math.abs(taglineParallax.startY) + taglineParallax.endY

  const [isFullHeight, setFullHeight] = useState(true)
  useEffect(() => {
    setFullHeight(context.viewport.isFullHeight)
  }, [context.viewport])

  useEffect(() => {
    let resizeReference
    if (props.parallax) {
      parallaxStart = wrapper.current.offsetTop - wrapper.current.offsetHeight
      parallaxEnd = wrapper.current.offsetTop + wrapper.current.offsetHeight
      gsap.set(tagline.current, { left: taglineParallax.startY })
      window.addEventListener('scroll', onScroll)
      resizeReference = debouncedResize(onResize)
    }

    return () => {
      window.removeEventListener('scroll', onScroll)
      clearDebouncedResize(resizeReference)
    }
  }, [])

  function onResize() {
    if (wrapper.current) {
      parallaxStart = wrapper.current.offsetTop - wrapper.current.offsetHeight
      parallaxEnd = wrapper.current.offsetTop + wrapper.current.offsetHeight
    }
  }

  function onScroll() {
    if (window.scrollY > parallaxStart && window.scrollY < parallaxEnd) {
      const delta = window.scrollY - parallaxStart
      // Normalizirat vrednosti, da dobiš od yStart do yEnd
      const deltaNormal = delta / (parallaxEnd - parallaxStart)

      gsap.to(tagline.current, { x: distance * deltaNormal, z: 0, duration: 0.6 })
    }
  }

  function onProjectClick() {
    dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })
  }

  return (
    <section
      id={`featured-project-${props.index}`}
      className={`${styles.scrollWrapper} ${props.isSticky ? styles.sticky : ''}`}
      style={{ zIndex: props.index + 10 }}
      ref={wrapper}
    >
      <div className={`${styles.root}`} id={`featured-project-${props.index}`}>
        <div className={styles.background}>
          <SanityImage image={props.featuredImage} width={1920} responsive loading="eager" />
        </div>

        <div className={styles.content}>
          {!props.isSticky && (
            <LinkTransition to={props.link} exitTrigger={onProjectClick}>
              <h2 className={styles.relativeFixedHeadline}>{props.headlineText}</h2>
            </LinkTransition>
          )}
          <div className={styles.taglineWord}>
            <LinkTransition to={props.link} exitTrigger={onProjectClick}>
              <span>
                <h3 className={styles.taglineTitle} ref={tagline}>
                  {props.taglineWord}
                </h3>
              </span>
            </LinkTransition>
          </div>

          <div className={`${styles.details}`}>
            <LinkTransition to={props.link} exitTrigger={onProjectClick}>
              <h3 className={styles.title}>{props.title}</h3>
            </LinkTransition>

            <div className={styles.link}>
              <LinkTransition to={props.link} exitTrigger={onProjectClick}>
                <span>
                  {props.viewProjectText}
                  <img src={arrows} alt="" />
                </span>
              </LinkTransition>
            </div>

            <div className={styles.awardsInner}>
              {props.awards.map(
                (award, index) =>
                  award.whiteImage && (
                    <div key={index}>
                      <img
                        src={award.whiteImage.asset.fixed.src}
                        alt={award.title}
                        srcSet={award.whiteImage.asset.fixed.src}
                      />
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

FeaturedProject.propTypes = {
  featuredImage: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  taglineWord: PropTypes.string.isRequired,
  slug: PropTypes.object.isRequired,
  index: PropTypes.number,
  link: PropTypes.string.isRequired,
  viewProjectText: PropTypes.string.isRequired,
  onInView: PropTypes.func,
  isSticky: PropTypes.bool.isRequired,
  headlineText: PropTypes.string,
  backgroundImage: PropTypes.bool,
  image: PropTypes.bool,
  parallax: PropTypes.bool,
  awards: PropTypes.array
}

export default FeaturedProject
