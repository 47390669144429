import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Hero.module.css";
import Clouds from "./Clouds";
import gsap from "gsap";
import HeroContent from "./HeroContent";
import UfoAnimation from "./UfoAnimation";
import Loader from "./Loader";

class Hero extends Component {
	static propTypes = {
		onReady: PropTypes.func.isRequired,
		onLoaded: PropTypes.func.isRequired,
		locale: PropTypes.oneOf(["sl", "en"]).isRequired,
		isVisible: PropTypes.bool.isRequired,
		skipAnimations: PropTypes.bool,
		pageSticky: PropTypes.bool.isRequired,
		pageLoaded: PropTypes.bool.isRequired,
		cachedImages: PropTypes.array,
		transitionStatus: PropTypes.string.isRequired,
		hasUfo: PropTypes.bool,
		showLoader: PropTypes.bool,
	};
	constructor(props) {
		super(props);
		this.hasWindow = typeof window !== "undefined";
		this.stickySet = false;
		this.ufoTimeoutReference = null;

		this.state = {
			contentShown: false,
			showUFO: false,
			isVisible: true,
			animationsPaused: false,
			isSticky: true,
			stage: {
				elements: [
					{
						id: "bush",
						loaded: true,
						outsideStage: true,
						image: require("../../images/hero/bush1.svg"),
					},
					{
						id: "tree",
						loaded: true,
						outsideStage: true,
						image: require("../../images/hero/tree-animation.svg"),
					},
					{
						id: "dog",
						loaded: true,
						image: require("../../images/hero/dog-animation.svg"),
					},
					{
						id: "barbecue",
						loaded: true,
						image: require("../../images/hero/barbecue.svg"),
					},
					{
						id: "men",
						loaded: true,
						image: require("../../images/hero/male.svg"),
					},
					{
						id: "woman",
						loaded: true,
						image: require("../../images/hero/skyping-animation.svg"),
					},
					{
						id: "badge",
						loaded: false,
						image: require("../../images/netko.png"),
						image2x: require("../../images/netko@2x.png"),
					},
				],
			},
		};
	}

	componentDidMount() {
		// Preload all hero elements.
		// this.startPreloading()
		this.props.onLoaded();
	}
	componentDidUpdate(prevProps) {
		if (prevProps.isVisible !== this.props.isVisible) {
			this.setState({ isVisible: this.props.isVisible });
		}
		if (prevProps.isVisible !== this.props.isVisible) {
			this.setState({ animationsPaused: !this.props.isVisible });
		}
		if (prevProps.pageLoaded !== this.props.pageLoaded) this.onPageLoaded();

		if (!this.stickySet) {
			this.setState({ isSticky: this.props.pageSticky });
			this.stickySet = true;
		}
	}

	componentWillUnmount() {
		if (this.ufoTimeoutReference) clearTimeout(this.ufoTimeoutReference);
	}

	constructionCompleted() {
		// Show elements and content
		// Trigger hero ready event
		// After certain time, start UFO animation
		this.showElements(() => {
			this.setState({ contentShown: true });
			if (this.props.onReady) this.props.onReady();

			this.ufoTimeoutReference = setTimeout(() => {
				this.setState({ showUFO: true });
			}, 1000);
		});
	}

	onPageLoaded() {
		// All hero elements prelaoded and other page assets such as fonts and stuff
	}

	showElements(callback) {
		const ids = this.state.stage.elements.map((el) => "#element-" + el.id);
		const contentIds = [
			"#hero-content-title",
			"#hero-content-subtitle",
			"#hero-content-stronger",
			"#hero-content-arrow",
		];

		// If we are coming from another page we show content ASAP, without animation
		if (this.props.transitionStatus === "entering") {
			gsap.set([...ids, ...contentIds], { opacity: 1, scale: 1, y: 0 });
			return callback();
		}

		// We should display them with a stagger one by one
		// Use gsap to stagger
		gsap.fromTo(
			[...ids, ...contentIds],
			{ y: -10, opacity: 0 },
			{
				duration: 0.6,
				opacity: 1,
				scale: 1,
				y: 0,
				stagger: { amount: 1.2, ease: "none" },
				onComplete: () => callback(),
			}
		);
	}

	render() {
		/*
		// THIS IS 100% 100% video of AGENCIJA 101
		const element = (
			<iframe
				src="https://player.vimeo.com/video/888644042?h=fa2dd5b1cc&amp;badge=0&amp;autopause=0&amp;background=1&amp;muted=1&amp;autoplay=1&amp;loop=1&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
				frameBorder="0"
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
				}}
				allow="autoplay; fullscreen; picture-in-picture"
				title="A101_showreel_F"
			></iframe>
		);

		const videoScripts = (
			<script src="https://player.vimeo.com/api/player.js"></script>
		);
		
			// YOU PUT THIS DIV INSIDE RETURN
			<div>
				{this.props.locale == "sl" ? (
					<section
						id="hero"
						className={`${styles.rootVIDEO} ${
							this.state.isSticky ? styles.sticky : ""
						} `}
					>
						<div className={styles.stage}>
							<div
								className={`${styles.element} ${styles.construction} ${styles.shown}`}
								hidden
							>
								<Loader
									locale={this.props.locale}
									pageLoaded={this.props.pageLoaded}
									onConstructionCompleted={this.constructionCompleted.bind(
										this
									)}
									skipAnimations={true}
								></Loader>
							</div>
						</div>

						<div
							style={{
								height: "100%",

								padding: "56.25% 0 0 0",
								position: "relative",
							}}
						>
							{element}
							{videoScripts}
						</div>
					</section>
				) : (
					<section
						id="hero"
						className={`${styles.root} ${
							this.state.isSticky ? styles.sticky : ""
						} ${!this.props.isVisible ? styles.notVisible : ""} ${
							this.props.skipAnimations ? styles.skipAnimations : ""
						}`}
					>
						<div className={`${styles.inner} `}>
							<div className={styles.sky}>
								{this.hasWindow && <Clouds></Clouds>}
							</div>
							<div className={styles.ground}></div>

							{this.state.stage.elements.map((element) => {
								if (!element.outsideStage || !element.loaded) return null;
								return (
									<div
										id={`element-${element.id}`}
										className={`${styles.element} ${styles[element.id]}`}
										key={element.id}
									>
										<img src={element.image} alt="" />
									</div>
								);
							})}

							<div className={styles.stage}>
								<div
									className={`${styles.element} ${styles.construction} ${styles.shown}`}
								>
									<Loader
										locale={this.props.locale}
										pageLoaded={this.props.pageLoaded}
										onConstructionCompleted={this.constructionCompleted.bind(
											this
										)}
										skipAnimations={this.props.transitionStatus === "entering"}
									></Loader>
								</div>

								{this.state.stage.elements.map((element) => {
									if (element.outsideStage || !element.loaded) return null;
									return element.lottie === true ? (
										<div
											id={`element-${element.id}`}
											className={`${styles.element} ${styles[element.id]}`}
											key={element.id}
										>
											<LottieAnimation
												name={element.animationName}
												renderer="svg"
												load
											></LottieAnimation>
										</div>
									) : (
										<div
											id={`element-${element.id}`}
											className={`${styles.element} ${styles[element.id]}`}
											key={element.id}
										>
											<img
												src={element.image}
												alt=""
												srcSet={element.image2x ? `${element.image2x} 2x` : ""}
											/>
										</div>
									);
								})}
							</div>

							<UfoAnimation
								play={!this.state.animationsPaused}
								show={this.state.showUFO}
							></UfoAnimation>

							<HeroContent locale={this.props.locale}></HeroContent>
						</div>
					</section>
				)}
			</div>
		
		*/

		return (
			<div>
				<section
					id="hero"
					className={`${styles.root} ${
						this.state.isSticky ? styles.sticky : ""
					} ${!this.props.isVisible ? styles.notVisible : ""} ${
						this.props.skipAnimations ? styles.skipAnimations : ""
					}`}
				>
					<div className={`${styles.inner} `}>
						<div className={styles.sky}>
							{this.hasWindow && <Clouds></Clouds>}
						</div>
						<div className={styles.ground}></div>

						{this.state.stage.elements.map((element) => {
							if (!element.outsideStage || !element.loaded) return null;
							return (
								<div
									id={`element-${element.id}`}
									className={`${styles.element} ${styles[element.id]}`}
									key={element.id}
								>
									<img src={element.image} alt="" />
								</div>
							);
						})}

						<div className={styles.stage}>
							<div
								className={`${styles.element} ${styles.construction} ${styles.shown}`}
							>
								<Loader
									locale={this.props.locale}
									pageLoaded={this.props.pageLoaded}
									onConstructionCompleted={this.constructionCompleted.bind(
										this
									)}
									skipAnimations={this.props.transitionStatus === "entering"}
								></Loader>
							</div>

							{this.state.stage.elements.map((element) => {
								if (element.outsideStage || !element.loaded) return null;
								return element.lottie === true ? (
									<div
										id={`element-${element.id}`}
										className={`${styles.element} ${styles[element.id]}`}
										key={element.id}
									>
										<LottieAnimation
											name={element.animationName}
											renderer="svg"
											load
										></LottieAnimation>
									</div>
								) : (
									<div
										id={`element-${element.id}`}
										className={`${styles.element} ${styles[element.id]}`}
										key={element.id}
									>
										<img
											src={element.image}
											alt=""
											srcSet={element.image2x ? `${element.image2x} 2x` : ""}
										/>
									</div>
								);
							})}
						</div>

						<UfoAnimation
							play={!this.state.animationsPaused}
							show={this.state.showUFO}
						></UfoAnimation>

						<HeroContent locale={this.props.locale}></HeroContent>
					</div>
				</section>
			</div>
		);
	}
}

export default Hero;
