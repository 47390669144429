import React from 'react'
import PropTypes from 'prop-types'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

const responsiveRules = [
  {
    // full-hd desktops
    viewportWidth: 1920,
    type: 'max-width',
    imageWidth: 1920
  },
  {
    // smaller desktops
    viewportWidth: 1600,
    type: 'max-width',
    imageWidth: 1600
  },
  {
    // tablets
    viewportWidth: 990,
    type: 'max-width',
    imageWidth: 1000
  },
  {
    // bigger phones or landscape
    viewportWidth: 650,
    type: 'max-width',
    imageWidth: 700
  },
  {
    // mobile
    viewportWidth: 450,
    type: 'max-width', // Viewport less than 675px gets image 700px wide
    imageWidth: 500
  }
]

function Image (props) {
  // For documentation on fetching images from Sanity: https://www.sanity.io/docs/image-urls
  const { width, height, image, fit, format, quality, responsive, obeyDPR } = props

  let baseSanityImage = imageUrlFor(buildImageObj(image))
    .fit(fit)
    .auto('format') // Auto load Webp if supported
    .quality(quality)

  if (typeof window !== 'undefined' && obeyDPR) { baseSanityImage = baseSanityImage.dpr(window.devicePixelRatio) }
  if (format) baseSanityImage = baseSanityImage.format(format)
  if (height !== 0) baseSanityImage = baseSanityImage.height(height)

  const baseSrc = baseSanityImage.width(props.width).url()

  // If responsive = true, generate srcsets and sizes for lower breakpoints than provided width
  let responsiveTags
  if (responsive) {
    // V width naj bi dobil največjo velikost - za vse manjše breakpointe zgeneriramo dodatne responsive URL-je
    /* EXAMPLE:
      <picture>
        <source media="(max-width: 799px)" srcset="elva-480w-close-portrait.jpg">
        <source media="(min-width: 800px)" srcset="elva-800w.jpg">
        <img src="elva-800w.jpg" alt="Chris standing up holding his daughter Elva">
      </picture>
    */

    responsiveTags = responsiveRules
      .map(rule => {
        // Zgeneriramo srcset samo če obstaja pravilo za manjšo širino slike
        if (rule.imageWidth < width) {
          return {
            srcset: `${baseSanityImage.width(rule.imageWidth).url()} ${rule.imageWidth}w`,
            media: `(${rule.type}: ${rule.viewportWidth}px)`
          }
        }
        return {}
      })
      .filter(tag => tag.media)
  }

  return (
    <picture>
      {responsive &&
        responsiveTags.map((tag, index) => (
          <source media={tag.media} srcSet={tag.srcset} key={index} />
        ))}
      <img
        src={baseSrc}
        alt={props.alt}
        loading={props.loading}
        width={props.width}
        height={props.height}
      />
    </picture>
  )
}

Image.propTypes = {
  image: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
  fit: PropTypes.oneOf(['clip', 'crop', 'fill', 'fillmax', 'max', 'scale', 'min']),
  alt: PropTypes.string,
  format: PropTypes.oneOf(['jpg', 'webp', 'png']),
  loading: PropTypes.oneOf(['lazy', 'eager', 'auto']),
  quality: PropTypes.number,
  responsive: PropTypes.bool,
  onLoad: PropTypes.func,
  obeyDPR: PropTypes.bool
}

Image.defaultProps = {
  loading: 'auto',
  alt: '',
  fit: 'clip',
  quality: 85,
  responsive: false
}

export default Image
