import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styles from './NextPage.module.css'
import arrDown from '../images/arr_down.svg'
import LinkTransition from './navigation/LinkTransition'
import { GlobalDispatchContext } from '../containers/GlobalContextProvider'

function NextPage ({ title, show, link, position, arrowForward, id, passState }) {
  const dispatch = useContext(GlobalDispatchContext)

  function onExit () {
    if (passState === 'scrollToProjectList') {
      dispatch({ type: 'SCROLL_TO_PROJECT_LIST', scrollToList: true })
    }
    dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })
  }

  return (
    <div
      className={`${styles.root} ${show ? styles.show : ''} ${
        position === 'relative' ? styles.relative : ''
      }`}
      id={id}
    >
      <div className={styles.background} />
      <LinkTransition to={link} label='' exitTrigger={onExit} />
      <div className={`${styles.content} ${arrowForward ? styles.hasArrowFwd : ''}`}>
        <div className={styles.title}>{title}</div>
        <div className={`${styles.arrow} ${arrowForward ? styles.arrowFwd : ''}`}>
          <img src={arrDown} alt='' />
        </div>
      </div>
    </div>
  )
}

NextPage.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool,
  link: PropTypes.string,
  position: PropTypes.string,
  arrowForward: PropTypes.bool,
  pageSticky: PropTypes.bool,
  id: PropTypes.string,
  passState: PropTypes.any
}

export default NextPage
